exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blinds-jsx": () => import("./../../../src/pages/blinds.jsx" /* webpackChunkName: "component---src-pages-blinds-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cornices-jsx": () => import("./../../../src/pages/cornices.jsx" /* webpackChunkName: "component---src-pages-cornices-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-reupholstery-jsx": () => import("./../../../src/pages/reupholstery.jsx" /* webpackChunkName: "component---src-pages-reupholstery-jsx" */),
  "component---src-pages-specialty-jsx": () => import("./../../../src/pages/specialty.jsx" /* webpackChunkName: "component---src-pages-specialty-jsx" */),
  "component---src-pages-windowtreatments-jsx": () => import("./../../../src/pages/windowtreatments.jsx" /* webpackChunkName: "component---src-pages-windowtreatments-jsx" */)
}

